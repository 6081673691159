.navbar-expand-md {
  background-color: #fefcf7;
}

.nav {
  color: #333333 !important;
  overflow: hidden;
  display: flex;
  justify-content: space-between !important;
  padding: 15px 20px 10px 20px !important;
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  opacity: 100% !important;
  border: none !important;
}

.navbar-light .navbar-toggler-icon {
  background-image: url(../../images/menu.png) !important;
  width: 45px;
}

/* Gets rid of border around hamburger icon on focus and active */
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none !important;
  box-shadow: none !important;
}

.NavTop {
  background-color: #faf2eb;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 15px;
  font-family: Mattone-150;
  text-transform: uppercase;
}

.nav a {
  text-decoration: none;
}

.nav-title {
  font-size: 42px !important;
  color: #333333;
  padding-left: 10px;
  margin: 0;
  font-family: Butler_Ultra_Light;
  font-size: 40px;
  text-transform: uppercase;
}

.navLink {
  display: inline-block;
  color: #333333;
  /* background-color: #00acb0; */
  /* text-align: center; */
  padding: 16px 10px !important;
  text-decoration: none;
  font-size: 15px;
  font-family: Mattone-150;

  /* these last two make the logout button in
  the nav bar look like another link */
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  outline: 0;
}

@media only screen and (max-width: 575px) {
  .nav-title {
    font-size: 32px !important;
    color: #333333;
    margin: 0;
    font-family: Butler_Ultra_Light;
    text-transform: uppercase;
    padding: 0px;
    margin-top: 10px;
  }

  .nav {
    color: #333333 !important;
    overflow: hidden;
    padding: 5px 10px !important;
  }
}
