/* xs screen */
@media only screen and (max-width: 576px) {
  .imageCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 90vh; */
  }
  .plantBackground {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
  }
  .PortraitOne {
    width: 70%;
    border-radius: 45% 45% 0% 0%;
    position: relative;
    transition: 0.5s;
    bottom: 27vh;
    margin-bottom: -22vh;
  }
  .sectionOneParagraph {
    font-family: Butler_Ultra_Light;
    font-size: 9vw;
    line-height: 1.2;
    /* padding-right: 70px; */
    text-align: center;
    /* position: relative;
    bottom: 0vh; */
    transition: 0.2s;
    /* margin-bottom: -50%; */
  }
  .textCol {
    display: flex;
    flex-direction: column;
  }
  .heyThere {
    font-family: BonVivant-Regular;
    font-size: 9vw;
    margin-left: 15px;
  }
  .heyThereDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* position: relative; */
    /* bottom: 390px; */
  }
  .heyThereHr {
    width: 60px;
    margin-left: 10px;
    border: 0.8px solid black;
    opacity: 100%;
    margin-top: 20px;
    transform: rotate(90deg);
    /* position: relative;
    bottom: 230px; */
    margin-bottom: 50px;
  }
}

/* small screen */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .imageCol {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .plantBackground {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
  }
  .PortraitOne {
    width: 60%;
    border-radius: 45% 45% 0% 0%;
    position: relative;
    transition: 0.5s;
    bottom: 260px;
  }
  .sectionOneParagraph {
    font-family: Butler_Ultra_Light;
    font-size: 9vw;
    line-height: 1.2;
    /* padding-right: 70px; */
    text-align: center;
    position: relative;
    bottom: 235px;
    transition: 0.2s;
    margin-bottom: -50%;
  }
  .textCol {
    display: flex;
    flex-direction: column;
  }
  .heyThere {
    font-family: BonVivant-Regular;
    font-size: 9vw;
    margin-left: 15px;
  }
  .heyThereDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    bottom: 230px;
  }
  .heyThereHr {
    width: 60px;
    margin-left: 10px;
    border: 0.8px solid black;
    opacity: 100%;
    margin-top: 20px;
    transform: rotate(90deg);
    /* position: relative;
    bottom: 230px; */
    margin-bottom: 50px;
  }
}

/* medium screen */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .imageCol {
    display: flex;
    flex-direction: row;
  }
  .plantBackground {
    width: 60%;
    height: 400px;
    position: relative;
    object-fit: cover;
    transition: 0.5s;
  }
  .PortraitOne {
    width: 500px !important;
    height: 350px;
    object-fit: cover;
    border-radius: 45% 45% 0% 0%;
    position: relative;
    top: 80px;
    right: 140px;
    transition: 0.5s;
  }
  .sectionOneParagraph {
    font-family: Butler_Ultra_Light;
    font-size: 4.6vw;
    line-height: 1.2;
    padding-right: 70px;
    transition: 0.2s;
    margin-top: -20px;
  }
  .heyThere {
    font-family: BonVivant-Regular;
    font-size: 4vh;
    margin-left: 20px;
  }
  .heyThereDiv {
    display: flex;
    position: relative;
    right: 8vw;
  }
  .heyThereHr {
    width: 60px;
    border: 0.1px solid black;
    opacity: 100%;
    margin-top: 20px;
    /* position: relative;
    bottom: 230px; */
    margin-bottom: 50px;
  }
  .textCol {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

/* large screen */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .imageCol {
    display: flex;
    flex-direction: row;
  }
  .plantBackground {
    width: 60%;
    height: 500px;
    position: relative;
    object-fit: cover;
    transition: 0.5s;
  }
  .PortraitOne {
    width: 500px !important;
    height: 450px;
    object-fit: cover;
    border-radius: 45% 45% 0% 0%;
    position: relative;
    top: 80px;
    right: 190px;
    transition: 0.5s;
  }
  .sectionOneParagraph {
    margin-top: -20px;

    font-family: Butler_Ultra_Light;
    font-size: 4.9vw;
    line-height: 1.2;
    padding-right: 70px;
    transition: 0.2s;
  }
  .heyThere {
    font-family: BonVivant-Regular;
    font-size: 3.8vw;
    margin-left: 3vw;
  }
  .heyThereDiv {
    display: flex;
    position: relative;
    right: 8vw;
  }
  .heyThereHr {
    width: 70px;
    border: 0.1px solid black;
    opacity: 100%;
    margin-top: 20px;
    /* position: relative;
    bottom: 230px; */
    margin-bottom: 50px;
  }
  .textCol {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

/* xl screen */
@media only screen and (min-width: 1200px) {
  .imageCol {
    display: flex;
    flex-direction: row;
  }
  .plantBackground {
    width: 64%;
    height: 600px;
    position: relative;
    object-fit: cover;
    transition: 0.5s;
  }
  .PortraitOne {
    width: 500px !important;
    height: 550px;
    object-fit: cover;
    border-radius: 45% 45% 0% 0%;
    position: relative;
    top: 80px;
    right: 250px;
    transition: 0.5s;
  }
  .sectionOneParagraph {
    font-family: Butler_Ultra_Light;
    font-size: 4.1vw;
    line-height: 1.2;
    padding-right: 200px;
    transition: 0.2s;
  }
  .heyThere {
    font-family: BonVivant-Regular;
    font-size: 3vw;
    margin-left: 1.2vw;
    margin-bottom: -50px;
  }
  .heyThereDiv {
    display: flex;
    position: relative;
    right: 6vw;
  }
  .heyThereHr {
    width: 75px;
    border: 0.1px solid black;
    opacity: 100%;
    margin-top: 20px;
    /* position: relative;
    bottom: 230px; */
    margin-bottom: 50px;
  }
  .textCol {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.background {
  background-color: #fefcf7;
  padding-bottom: 250px;
}
