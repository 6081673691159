@media only screen and (max-width: 576px) {
  .previousWorkContainerDiv {
    background-color: #faf2eb;
  }

  .previousWorkHrandTextDiv {
    display: flex;
    justify-content: space-between;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .previousWorkHr {
    border: 0.5px solid black;
    opacity: 100;
    width: 50%;
  }

  .previousWorkh2 {
    font-family: BonVivant-Regular;
    font-size: 35px;
  }

  .previousWorkDivs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .previousWorkDiv {
    background-color: #fefcf7;
    /* max-width: 500px; */
    width: 300px;
    min-height: 600px;
    font-family: Poppins-Light;
    font-size: 20px;
    padding: 30px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .previousWorkImage {
    max-height: 300px;
    width: 100%;
    margin-bottom: 30px;
  }

  .seeMoreButton {
    border: 1px solid black;
    font-weight: 0;
    font-size: 15px;
    letter-spacing: 2px;
    border-radius: 0px;
    padding: 20px 30px;
    width: 300px;
    font-family: Mattone-150;
    text-decoration: none;
    color: black;
    transition: 0.5s;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .previousWorkContainerDiv {
    background-color: #faf2eb;
  }

  .previousWorkHrandTextDiv {
    display: flex;
    justify-content: space-between;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .previousWorkHr {
    border: 0.5px solid black;
    opacity: 100;
    width: 55%;
  }

  .previousWorkh2 {
    font-family: BonVivant-Regular;
    font-size: 42px;
  }

  .previousWorkDivs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .previousWorkDiv {
    background-color: #fefcf7;
    /* max-width: 500px; */
    width: 520px;
    min-height: 700px;
    font-family: Poppins-Light;
    font-size: 26px;
    padding: 40px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .previousWorkImage {
    max-height: 300px;
    width: 100%;
    margin-bottom: 30px;
  }
  .seeMoreButton {
    border: 1px solid black;
    font-weight: 0;
    font-size: 15px;
    letter-spacing: 2px;
    border-radius: 0px;
    padding: 20px 70px;
    width: 300px;
    font-family: Mattone-150;
    text-decoration: none;
    color: black;
    transition: 0.5s;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .previousWorkContainerDiv {
    background-color: #faf2eb;
  }

  .previousWorkHrandTextDiv {
    display: flex;
    justify-content: space-between;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .previousWorkHr {
    border: 0.5px solid black;
    opacity: 100;
    width: 65%;
  }

  .previousWorkh2 {
    font-family: BonVivant-Regular;
    font-size: 43px;
  }

  .previousWorkDivs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .previousWorkDiv {
    background-color: #fefcf7;
    /* max-width: 500px; */
    width: 600px;
    min-height: 700px;
    font-family: Poppins-Light;
    font-size: 27px;

    padding: 40px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .previousWorkImage {
    max-height: 300px;
    width: 100%;
    margin-bottom: 30px;
  }
  .seeMoreButton {
    border: 1px solid black;
    font-weight: 0;
    font-size: 15px;
    letter-spacing: 2px;
    border-radius: 0px;
    padding: 20px 70px;
    width: 300px;
    font-family: Mattone-150;
    text-decoration: none;
    color: black;
    transition: 0.5s;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .previousWorkContainerDiv {
    background-color: #faf2eb;
  }

  .previousWorkHrandTextDiv {
    display: flex;
    justify-content: space-between;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .previousWorkHr {
    border: 0.5px solid black;
    opacity: 100;
    width: 76%;
  }

  .previousWorkh2 {
    font-family: BonVivant-Regular;
    font-size: 40px;
  }

  .previousWorkDivs {
    display: flex;
    justify-content: space-around;
  }
  .previousWorkDiv {
    background-color: #fefcf7;
    max-width: 450px;
    min-height: 500px;
    font-family: Poppins-Light;
    font-size: 21px;
    padding: 30px;
    margin-bottom: 50px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .previousWorkImage {
    max-height: 300px;
    width: 100%;
    margin-bottom: 30px;
  }
  .seeMoreButton {
    border: 1px solid black;
    font-weight: 0;
    font-size: 15px;
    letter-spacing: 2px;
    border-radius: 0px;
    padding: 20px 70px;
    width: 300px;
    font-family: Mattone-150;
    text-decoration: none;
    color: black;
    transition: 0.5s;
  }
}

@media only screen and (min-width: 1200px) {
  .previousWorkContainerDiv {
    background-color: #faf2eb;
  }

  .previousWorkHrandTextDiv {
    display: flex;
    justify-content: space-between;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .previousWorkHr {
    border: 0.5px solid black;
    opacity: 100;
    width: 79%;
  }

  .previousWorkh2 {
    font-family: BonVivant-Regular;
    font-size: 43px;
  }

  .previousWorkDivs {
    display: flex;
    justify-content: space-around;
  }
  .previousWorkDiv {
    background-color: #fefcf7;
    max-width: 530px;
    min-height: 600px;
    font-family: Poppins-Light;
    font-size: 21px;
    padding: 40px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .previousWorkImage {
    max-height: 300px;
    width: 100%;
    margin-bottom: 30px;
  }
  .seeMoreButton {
    border: 1px solid black;
    font-weight: 0;
    font-size: 15px;
    letter-spacing: 2px;
    border-radius: 0px;
    padding: 20px 70px;
    width: 300px;
    font-family: Mattone-150;
    text-decoration: none;
    color: black;
    transition: 0.5s;
  }
}

/* .seeMoreButton {
  border: 1px solid black;
  font-weight: 0;
  font-size: 15px;
  letter-spacing: 2px;
  border-radius: 0px;
  padding: 20px 70px;
  width: 300px;
  font-family: Mattone-150;
  text-decoration: none;
  color: black;
  transition: 0.5s;
} */

.seeMoreButton:hover {
  color: #c4b0a2;
  transition: 0.5s;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.paragraph {
  margin-bottom: 5vh;
}

.HannahFligel {
  font-family: Butler_Ultra_Light;
  font-size: 32px;
}
