.about-section {
  padding-top: 5vh;
  background-color: #fefcf7;
}

.about-title {
  font-family: Butler_Ultra_Light;
  font-size: 4.6vw;
  display: flex;
}

.large-text-cursive {
  font-family: BonVivant-Regular;
  font-size: 40px;
}

.large-text {
  font-family: Butler_Ultra_Light;
  font-size: 48px;
  margin-bottom: 0;
}

.about-hr {
  width: 80%;
  border: 0.7px solid black;
  opacity: 100%;
  margin-left: 2.5vw;
}

@media only screen and (max-width: 576px) {
  .about-section {
    padding: 5vh 4vw;
    display: flex;
    text-align: center;
  }

  .about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about-hr {
    transform: rotate(90deg);
    width: 25vw;
    margin-bottom: 10vh;
  }

  .large-text {
    margin-bottom: 5vh;
  }

  .about-me-text {
    font-size: 18px;
    font-family: Poppins-Regular;
    text-align: center;
  }

  .about-me-photo {
    margin-bottom: 3vh;
    object-fit: contain;
    max-height: 60vh;
    min-height: 50vh;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-me-photo {
    object-fit: contain;
    max-height: 400px;
    /* min-height: 40vh; */
    float: right;
    margin-left: 20px;
    position: relative;
    bottom: 330px;
    margin-bottom: -320px;
  }

  .large-text {
    font-family: Butler_Ultra_Light;
    font-size: 45px;
    margin-bottom: 24px;
    width: 250px;
  }

  .about-me-text {
    font-size: 18px;
    font-family: Poppins-Regular;
  }

  .about-hr {
    display: none;
  }

  .about-me-text {
    font-size: 18px;
    font-family: Poppins-Regular;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .about-hr {
    display: none;
  }

  .about-me-photo {
    margin-bottom: -350px;
    object-fit: contain;
    max-height: 500px;
    float: right;
    margin-left: 20px;
    position: relative;
    bottom: 350px;
  }

  .about-me-text {
    font-size: 18px;
    font-family: Poppins-Regular;
    /* text-align: justify; */
  }

  .large-text {
    font-family: Butler_Ultra_Light;
    font-size: 48px;
    margin-bottom: 24px;
    width: 250px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .about-hr {
    display: none;
  }

  .about-me-photo {
    margin-bottom: -270px;
    object-fit: contain;
    max-height: 650px;
    min-height: 550px;
    float: right;
    margin-left: 5vw;
    position: relative;
    bottom: 270px;
  }

  .about-me-text {
    font-size: 18px;
    font-family: Poppins-Regular;
  }

  .large-text {
    font-family: Butler_Ultra_Light;
    font-size: 48px;
    margin-bottom: 3vh;
  }
}

@media only screen and (min-width: 1200px) {
  .about-hr {
    display: none;
  }

  .about-me-photo {
    margin-bottom: -275px;
    object-fit: contain;
    max-height: 75vh;
    min-height: 600px;
    float: right;
    margin-left: 2vw;
    margin-right: 5vw;
    position: relative;
    bottom: 275px;
  }

  .about-me-text {
    font-size: 18px;
    font-family: Poppins-Regular;
  }

  .large-text {
    font-family: Butler_Ultra_Light;
    font-size: 48px;
    margin-bottom: 3vh;
  }
}
