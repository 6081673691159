@font-face {
  font-family: "Mattone-150";
  src: local("Mattone-150"),
    url("../../fonts/Mattone-150.otf") format("truetype");
}

@font-face {
  font-family: "Butler_Ultra_Light";
  src: local("Butler_Ultra_Light"),
    url("../../fonts/Butler_Light.otf") format("truetype");
}

@font-face {
  font-family: "BonVivant-Regular";
  src: local("BonVivant-Regular"),
    url("../../fonts/BonVivant-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url("../../fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  src: local("Poppins-Light"),
    url("../../fonts/Poppins-Light.ttf") format("truetype");
}

html,
body,
#react-root {
  height: 100%;
}

body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 0px;
}

img {
  display: inline-block;
  max-width: 100%;
}

/* --------------------------------------------------------------------------------
Container
-------------------------------------------------------------------------------- */

.container {
  padding: 20px;
}

/* --------------------------------------------------------------------------------
Button
-------------------------------------------------------------------------------- */

.alert {
  box-sizing: border-box;
  padding: 15px 10px;
  margin: 15px 0;
  border: 2px solid #e01616;
  background-color: #efefef;
  border-radius: 3px;
  color: #e01616;
  font-size: 0.8rem;
}


