footer {
  margin-top: 80px;
  padding: 20px;
  /* text-align: center; */
  background-color: #f7f2eb;
  font-size: 13px;
  font-family: Poppins-Light;
  /* display: flex; */
  padding: 30px;
}

.footer-all {
  /* background-color: #f7f2eb; */
}

.marina {
  text-decoration: none;
  color: black;
  transition: 0.5s;
}

.marina:hover {
  text-decoration: none;
  color: #8e7e72;
  transition: 0.5s;
}

.Icon {
  height: 20px;
}

/* .container {
  display: flex;
} */

.footer-column {
  display: flex;
  flex-direction: column;
}

.footer-column-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-column-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.footer-column-end img {
  margin-left: 12px;
}

footer {
  margin-top: 0;
}

.footer-top {
  background-color: #fefcf7;
}

.email {
  display: flex;
  text-decoration: none;
  color: black;
}

.email:hover {
  display: flex;
  text-decoration: none;
  color: black;
}

.email img {
  margin-right: 6px;
}

.email p {
  margin: 0;
}

.topIcon {
  height: 25px;
}
