.sectionTwoDiv {
  background-color: #fae6da;
  /* margin-top: 170px; */
  padding-top: 80px;
  padding-bottom: 150px;
}

.skillsHeading {
  font-family: Butler_Ultra_Light;
  font-size: 4.6vw;
}

.skillseHr {
  width: 60px;
  margin-left: 10px;
  border: 0.6px solid black;
  opacity: 100%;
  margin-top: 70px;
  transform: rotate(90deg);
  margin-bottom: 75px;
}

.skillsDiv {
  display: flex;
  justify-content: space-around;
}

.skillsItem {
  min-height: 370px;
  /* width: 270px; */
  background-color: #fefcf7;
  /* margin-left: 20px !important; */
  /* margin-right: 20px !important; */
  margin-bottom: 20px;
}

.skillsH2 {
  font-family: Butler_Ultra_Light;
  font-size: 48px;
}

.skillsHeader {
  font-family: Butler_Ultra_Light;
  margin-bottom: 20px;
}

.skillsParagraph {
  font-family: Poppins-Regular;
}

.skillsIcon {
  height: 55px;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 576px) {
  .skillsItem {
    min-height: 500px;
    background-color: #fefcf7;
    margin-bottom: 20px;
    max-width: 370px !important;
  }
  .skillsHeader {
    font-family: Butler_Ultra_Light;
    font-size: 42px;
  }

  .skillsParagraph {
    font-family: Poppins-Light;
    font-size: 23px;
    margin-right: 20px;
    margin-left: 20px;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .skillsItem {
    min-height: 500px;
    background-color: #fefcf7;
    margin-bottom: 20px;
    max-width: 370px !important;
  }
  .skillsHeader {
    font-family: Butler_Ultra_Light;
    font-size: 42px;
  }

  .skillsParagraph {
    font-family: Poppins-Light;
    font-size: 23px;
    margin-right: 20px;
    margin-left: 20px;
    text-align: center;
  }
}
